import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const ViewQuestionsModal = ({ videoCode, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!videoCode) return;
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API_URL}/video_guide?video_url=https://www.youtube.com/embed/${videoCode}`)
            .then((response) => {
                if (response.data) {
                    setData(response.data.data.subtopics);
                }
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setLoading(false)
            })
    }, [videoCode])

    return (
        <Modal show onHide={onClose} centered size="xl" style={{ zIndex: 10000 }}>
            <Modal.Header closeButton>
                <Modal.Title>View Question</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ overflowY: "auto", maxHeight: "500px" }}>
                {loading && "Loading..."}
                {data.map(row => (
                    <>
                        {row.heading}
                        <ol>
                            {Object.values(row.augmentation.hints).map((hint) => (
                                <li>{hint.hint}</li>
                            ))}
                        </ol>
                    </>
                ))}
            </Modal.Body>
        </Modal>
    );
}

export default ViewQuestionsModal;