import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import SearchListTable from './search-list-table';
import Breadcrumbs from '../components/breadcrumbs';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

const VideoSeachComponents = () => {

    const [showSpinner, setShowSpinner] = useState(false);
    const [courseId, setCourseId] = useState(0);
    const [courseName, setCourseName] = useState();
    const [course, setCourse] = useState();
    const [unit, setUnit] = useState();
    const [lesson, setLesson] = useState();
    const [courseArray, setCourseArray] = useState([]);
    const [unitArray, setUnitArray] = useState([]);
    const [lessonArray, setLessonArray] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [unitId, setUnitId] = useState();
    const [unitTitle, setUnitTitle] = useState();
    const [query, setQuery] = useState();
    const [maxResult, setMaxResult] = useState('');
    const [lessonContent, setLessonContent] = useState('');
    const [lessonId, setLessonId] = useState('');
    const [lessonName, setLessonName] = useState('');

    const [settingss, setSettingss] = useState({});
    const [minView, setMinView] = useState('');
    const [MinLike, setMinLike] = useState('');
    const [relavacyPrompt, setRelavacyPrompt] = useState('');

    const [saveChecked, setSaveChecked] = useState(false);

    const [preferredChecked, setPreferredChecked] = useState(false);
    const [prefferdChannelList, setPrefferdChannelList] = useState([]);

    const [selectedPreChannels, setSelectedPreChannels] = useState([]);
    const [savedkey, setSavedkey] = useState('');
    const [searchKey, setSearchKey] = useState('');

    const [profileOpt, setProfileOpt] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    // const [searchKey, setSearchKey] = useState('');
    const [processStatus, setProcessStatus] = useState(false);

    const handleChannelChange = (selectedOptions) => {
        setSelectedPreChannels(selectedOptions);

    };

    const customStyles = {
        control: (provided) => ({
            ...provided,
            width: '100%', // Set the desired width here
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#e0e0e0',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#000',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: 'red',
            ':hover': {
                backgroundColor: 'lightgrey',
                color: 'white',
            },
        }),
    };

    let searchId = localStorage.getItem('search_id') ? localStorage.getItem('search_id') : 0
    const navigate = useNavigate();
    const url = `${process.env.REACT_APP_API_URL}/video-finder-search`;
    useEffect(() => {
        let formData_1 = localStorage.getItem('tokenData')
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_webservice_get_site_info', formData_1)
            .then(userdata => {
                if (!userdata.data.userid) {
                    localStorage.setItem('login', false)
                    navigate('/');
                }
            })
            .catch(error => {
                console.log(error)
            });
    }, [])
    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        const [id, name] = selectedValue.split('%%');
        setCourseId(id);
        setCourse(e.target.value);
        setCourseName(name);
    };
    const handleDescriptionChange = (e) => {
        setLessonContent(e.target.value)
    };
    const handleUnitChange = (e) => {
        const selectedValue = e.target.value;
        const [id, title] = selectedValue.split('%%');
        setUnitId(id);
        setUnit(e.target.value);
        setUnitTitle(title);
    };
    const handleLessonChange = (e) => {
        const selectedValue = e.target.value;
        const [id, title, content] = selectedValue.split('%%');
        setLessonId(id);
        setLessonName(title);
        setLesson(e.target.value);
        setLessonContent(htmlConverter(content))
    }
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_unit_data&moodlewsrestformat=json&course_id=' + courseId)
            .then((response) => {
                if (response.data) {
                    const decodedData = JSON.parse(response.data.data);
                    let units = decodedData[0].units;
                    setUnitArray(validateUnits(units));
                }
            })
            .catch((err) => {
                console.log(err)
            })
        setPrefferdChannelList([]);
    }, [courseId])
    useEffect(() => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_unit_lessions_data&moodlewsrestformat=json&unit_id=' + unitId)
            .then((response) => {
                if (response.data) {
                    setLessonArray(response.data.data);
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [unitId])
    const handleReset = () => {
        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
            .then((response) => {
                setCourseArray(response.data.data);
                setQuery('');
                setCourseId(0);
                setCourseName('');
                setUnitId('');
                setUnitTitle('');
                setUnitArray([]);
                setCourse('');
                setLessonId('');
                setLessonContent('');
                setLessonArray([]);
                setLessonName('');
                setPreferredChecked(false)
                setSaveChecked(false);
                getSettings();
            })
    };
    const handleScrolDown = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0,
            behavior: 'smooth',
        });
    }
    const handleClick = () => {
        if (courseId && unitId && query && maxResult && lessonContent && lessonId) {

            var selectedChannels = [];
            if (selectedPreChannels) {
                selectedChannels = selectedPreChannels.map(ch => ch.value);
            }


            if (maxResult > 50) {
                toast.error('max result should not be morethan 50')
            } else {
                setShowSpinner(true);
                const params = {
                    query: query,
                    course_id: courseId,
                    course_name: courseName,
                    unit_id: unitId,
                    unit_title: unitTitle,
                    lesson_id: lessonId,
                    lesson_name: lessonName,
                    lesson_content: lessonContent,
                    max_result: maxResult,
                    preferred_channels: selectedChannels,
                    relevency_prompt: relavacyPrompt,
                    key_word: savedkey,
                    minimum_like: MinLike,
                    minimum_view: minView,
                    save_search: savedkey ? true : false,
                    video_url: "None",
                    replacement_search: "False"
                };

                axios.post(url, params, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then(response => {
                    localStorage.setItem('search_id', response.data.data)
                    setShowSpinner(false);
                    handleReset();
                    handleScrolDown();
                })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            }
        } else {
            toast.error('Please complete all fields - they are mandatory.')
        }
    }
    useEffect(() => {
        getSearchedList()
    }, [searchId])
    const getSearchedList = () => {
        axios.put(`${url}?search_id=${searchId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
            .then(response => {
                setTableData(response.data.data);
            })
            .catch(error => {
                console.log("error", error)
            })
    }
    const checkMaxResult = (value) => {
        const numRejex = /^[0-9]*$/

        if (numRejex.test(value)) {
            setMaxResult(value)
        }
    }

    const checkMinLike = (value) => {
        const numRejex = /^[0-9]*$/

        if (numRejex.test(value)) {
            setMinLike(value)
        }
    }
    const checkMinView = (value) => {
        const numRejex = /^[0-9]*$/

        if (numRejex.test(value)) {
            setMinView(value)
        }
    }

    const validateUnits = (data) => {
        const temp = data.slice()
        const val = temp.filter((unit) => (unit.name.trim() !== "") && unit.name != '.');
        return val
    }
    const htmlConverter = (data) => {
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = data;
        var plainText = tempDiv.textContent || tempDiv.innerText;
        const parser = new DOMParser();
        const doc = parser.parseFromString(plainText, 'text/html');
        const textContent = doc.body.textContent || '';
        return textContent
    }


    useEffect(() => {
        getSettings();
        getPreffered();
    }, [])

    const getSettings = () => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-settings`;

        axios.get(apiUrl)
            .then(response => {
                setSettingss(response.data.data);
                setMaxResult(response.data.data.maximum_result);
                setRelavacyPrompt(response.data.data.new_search_prompt);
            })
            .catch(error => {
                console.log("error", error)
            })

    }
    const handleSaveChange = () => {
        setSaveChecked(!saveChecked);
    };


    const handleSavekeyChange = (e) => {
        setSavedkey(e.target.value);
    };

    const handlePreferredChange = () => {
        setPreferredChecked(!preferredChecked);
    };

    // const handleSearchKey = (e) => {
    //     setSearchKey(e.target.value);
    //     getProfile();
    // };
    useEffect(() => {
        if (preferredChecked) {
            if (courseId) {
                getPreffered(courseId);
            } else {
                toast.error('Please select a course before select channel.')
            }
        }


    }, [preferredChecked])


    const getPreffered = (courseId) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-preferred-channel`;
        const params = {
            course_id: courseId
        };
        axios.get(apiUrl, { params })
            .then(response => {
                const options = response.data.data.map(channel => ({
                    value: channel.channel_id,
                    label: channel.title
                }));

                setPrefferdChannelList(options);
            })
            .catch(error => {
                console.log("error", error)
            })

    }

    const getProfile = (searchKey) => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-search`;
        const params = {
            key_word: searchKey
        };
        axios.get(apiUrl, { params })
            .then(response => {
                // setProfileOpt(response.data.data);
                // setFilteredOptions(response.data.data);


                const formattedOptions = response.data.data.map(item => ({
                    value: item.id, // Adjust based on your data structure
                    label: item.key_word // Adjust based on your data structure
                }));
                setOptions(formattedOptions);

            })
            .catch(error => {
                console.log("error", error)
            })
    }
    const handleSearchKe = (event) => {
        const value = event.target.value;
        setSearchKey(value);
        if (profileOpt) {
            const filtered = profileOpt.filter(item =>
                item.key_word.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredOptions(filtered);
        }

        getProfile();

    };

    const handleOptionSelect = (option) => {
        setSearchKey(option.name); // Set input to selected option's name
        setFilteredOptions([]); // Clear options after selection
    };
    const handleRelavencyChange = (e) => {
        setRelavacyPrompt(e.target.value)
    };

    const [options, setOptions] = useState([]);
    const [form_data, setForm_data] = useState({});
    const [culData, setculData] = useState([]);
    const [isPreferred, setIsPreferred] = useState(false);

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, ''); // Optionally sanitize input
        setSearchKey(inputValue);

        if (inputValue) {
            getProfile(inputValue); // Fetch data on input change
        } else {
            setOptions([]); // Clear options if input is empty
        }
    };


    useEffect( () => {
        setCourse(`${culData.course_id}%%${culData.course_name}`);
        setCourseId(culData.course_id);
        setUnit(`${culData.unit_id}%%${culData.unit_name}`)
        setUnitId(culData.unit_id);
        setLessonId(culData.lesson_id);
        setCourseName(culData.course_name);
        setUnitTitle(culData.unit_name);
        // setLesson(`${culData.lesson_id}%%${culData.lesson_name}`)

        //selectedLessonId ? `${selectedLessonId}%%${lessonArray.find(item => item.Id === selectedLessonId).Lession_Name}%%${lessonArray.find(item => item.Id === selectedLessonId).Lession_Content}` : ''}
        

    }, [culData])

    useEffect(()=>{
        if (lessonArray && culData)  {
            
            const less=lessonArray.find(item => item.Id == culData.lesson_id);
            if(less){
                setLesson(less.Id+"%%"+less.Lession_Name+"%%"+less.Lession_Content);
           
            
            }
            

        }
    },[lessonArray])

    const setForm = async (prop) => {
        if (prop) {
            const apiUrl = `${process.env.REACT_APP_API_URL}/video-finder-search`;
            const params = {
                id: prop.value
            };
            axios.get(apiUrl, { params })
                .then(response => {
    
                    // console.log(response)
    
                    if (response.data.data) {
                        setCourseId(response.data.data[0].course_id);
                        setForm_data(response.data.data[0]);
                        getculDetails(response.data.data[0].course_id, response.data.data[0].unit_id, response.data.data[0].lesson_id);
                        setLessonContent(response.data.data[0].lesson_content);
                        setMaxResult(response.data.data[0].maximum_result);
                        setMinLike(response.data.data[0].minimum_like);
                        setMinView(response.data.data[0].minimum_view)
                        setQuery(response.data.data[0].query);
                        setRelavacyPrompt(response.data.data[0].relevancy_prompt)
                        if(JSON.parse(response.data.data[0].preferred_channel).length){
                            setSelectedPreChannels(JSON.parse(response.data.data[0].preferred_channel))
                            setPreferredChecked(true)
                        }
                    }
    
                })
        }

        


        // axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_course_data&moodlewsrestformat=json')
        //     .then((response) => {
        //         setCourseArray(response.data.data);
        //         setQuery('');

        //         setCourseName('');
        //         setUnitId('');
        //         setUnitTitle('');
        //         setUnitArray([]);
        //         setCourse('');
        //         setLessonId('');
        //         
        //         setLessonArray([]);
        //         setLessonName('');

        //         getSettings();
        //     })

    }

    const getculDetails = (course, unit, lesson) => {

        axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?wstoken=' + process.env.REACT_APP_TOKEN + '&wsfunction=local_course_service_get_cul_name&moodlewsrestformat=json&course=' + course + '&unit=' + unit + '&lesson=' + lesson)
            .then((response) => {
                setculData(JSON.parse(response.data.data));
            })
    }
   
    useEffect(()=>{
        const timer = setTimeout(() => {
            if(processStatus){
                getSearchedList();
                setProcessStatus(false);
            }
            
          }, 10000); 
    },[processStatus])

    return (
        <section className="video-search-section">
            <Container fluid className='px-4'>
                <Row>
                    <Col xs="12">
                        <div className="search-form-container flex-1 py-3">
                            <Breadcrumbs />
                            <p className="section-title mb-2">Video Search</p>
                            <div className="form-block mt-3">
                                <div className="d-flex gap-3 flex-wrap">
                                    <Form style={{ width: "100%" }}>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="" className="mb-2" >
                                                    <div class="d-flex align-items-center">
                                                        <label class="me-3">Saved Search:</label>

                                                        <div className="position-relative">

                                                            <Select
                                                                onInputChange={handleInputChange}
                                                                options={options}
                                                                placeholder="Enter keyword"
                                                                isClearable
                                                                isSearchable
                                                                noOptionsMessage={() => "No options available"}
                                                                onChange={(selectedOption) => {
                                                                    setForm(selectedOption);
                                                                }}
                                                            />
                                                        </div>

                                                        {/* <Form.Control
                                                                type="text"
                                                                value={searchKey}
                                                                onChange={handleSearchKe}
                                                                autoComplete="off"
                                                                placeholder='Enter Key word'
                                                                style={{ paddingLeft: "35px" }}
                                                            /> */}
                                                        {/* <div className="search-icon" style={{ position: "absolute", bottom: "15px", left: "10px" }}>
                                                                <i className="fas fa-search" style={{ color: "#bbb" }} />
                                                            </div> */}
                                                        {/* {filteredOptions && filteredOptions.length > 0 && (
                                                                <ul style={{ border: '1px solid #ccc', maxHeight: '150px', overflowY: 'auto', margin: 0, padding: 0, listStyle: 'none' }}>
                                                                    {filteredOptions.map((item) => (
                                                                        <li
                                                                            key={item.id}
                                                                            onClick={() => handleOptionSelect(item)}
                                                                            style={{ cursor: 'pointer', padding: '5px' }}
                                                                        >
                                                                            {item.key_word}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )} */}


                                                        {/* <div class="position-relative">
                                                            <Form.Control type="text" value={searchKey} onChange={handleSearchKey} autoComplete="off" placeholder='Enter Key word ' style={{ paddingLeft: "35px" }} />
                                                            <div className="search-icon"
                                                           
                                                              style={{ position: "absolute", bottom: "15px", left: "10px" }}>
                                                                <i class="fas fa-search" style={{ color: "#bbb" }} />
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Form style={{ maxWidth: "30%", width: "100%" }}>
                                        <Col>
                                            <Row>
                                                <Form.Group className="pb-2" controlId="course">
                                                    {courseArray && <Form.Select
                                                        aria-label=""
                                                        value={course}
                                                        onChange={handleSelectChange}>
                                                        <option>Select course</option>
                                                        {courseArray.map(course => (
                                                            <option key={course.Course_ID} value={`${course.Course_ID}%%${course.Course_Name}`}>
                                                                {course.Course_Name}
                                                            </option>
                                                        ))}
                                                    </Form.Select>}
                                                </Form.Group>
                                                <Form.Group className="py-2" controlId="courseModuleDescription">
                                                    <Form.Control as="textarea" placeholder="Course Module Description" style={{ height: '180px', maxWidth: '100%' }} value={lessonContent} onChange={handleDescriptionChange} />
                                                </Form.Group>
                                                <Form.Group className="mb-4" controlId="searchQueryString">
                                                    <Form.Control type="text" value={query} placeholder="Search Query String" onChange={(e) => { setQuery(e.target.value) }} autoComplete="off" style={{ maxWidth: '100%' }} />
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Form>
                                    <Form style={{ maxWidth: "30%", width: "100%" }}>
                                        <Form.Group controlId="unit" className='d-flex align-items-center pb-2'>
                                            {unitArray && <Form.Select
                                                aria-label=""
                                                onChange={handleUnitChange}
                                                value={unit}
                                            >
                                                <option>Select unit</option>
                                                {unitArray.map(unit => (
                                                    <option key={unit.id} value={`${unit.id}%%${unit.name}`}>
                                                        {unit.name}
                                                    </option>
                                                ))}
                                            </Form.Select>}
                                        </Form.Group>
                                        <Form.Group className="py-2" controlId="Relevancy Prompt">
                                            <Form.Control as="textarea" placeholder="Relevancy Prompt" style={{ height: '180px', maxWidth: '100%' }} value={relavacyPrompt} onChange={handleRelavencyChange} />
                                        </Form.Group>
                                        <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                            <div className="d-flex gap-3 mt-auto">
                                                <Form.Control type="text" value={minView} onChange={(e) => { checkMinView(e.target.value) }} autoComplete="off" placeholder='Minimum View' className="me-1" />
                                                <Form.Control type="text" value={MinLike} onChange={(e) => { checkMinLike(e.target.value) }} autoComplete="off" placeholder='Minimum Likes' className="ms-1" />
                                            </div>
                                        </Form.Group>
                                    </Form>
                                    <Form style={{ maxWidth: "30%", width: "100%" }}>
                                        <Form.Group controlId="lesson" className='d-flex align-items-center pb-2'>
                                            {courseArray && <Form.Select
                                                aria-label=""
                                                onChange={handleLessonChange}
                                                value={lesson}
                                            >
                                                <option>Select lesson</option>
                                                {lessonArray && lessonArray.map(lesson => (
                                                    <option key={lesson.Id} value={`${lesson.Id}%%${lesson.Lession_Name}%%${lesson.Lession_Content}`}>
                                                        {lesson.Lession_Name}
                                                    </option>
                                                ))}
                                            </Form.Select>}
                                        </Form.Group>
                                        <Form.Group controlId="" className="pt-2 pb-2">
                                            <div key={'Restrict to Preferred Channels'} className="mb-1">
                                                <Form.Check // prettier-ignore
                                                    type="checkbox"
                                                    id="preferredCheckbox"
                                                    label="Restrict to Preferred Channels"
                                                    style={{ textAlign: "left" }}
                                                    onChange={handlePreferredChange}
                                                    checked={preferredChecked}

                                                />
                                            </div>
                                        </Form.Group>
                                        {/* <Form.Group controlId="preferdchannel" className='d-flex align-items-center pb-2'>
                                            {unitArray && <Form.Select

                                                onChange={handleUnitChange}
                                                disabled={!preferredChecked}
                                            >
                                                <option>Select channels</option>
                                                {unitArray.map(unit => (
                                                    <option key={unit.id} value={`${unit.id}%%${unit.name}`}>
                                                        {unit.name}
                                                    </option>
                                                ))}
                                            </Form.Select>}
                                        </Form.Group> */}


                                        <Form.Group controlId="prefferdchannel" className='d-flex align-items-center pb-2' >
                                            <Select
                                                isMulti
                                                name="prechannel"
                                                options={prefferdChannelList}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={handleChannelChange}
                                                placeholder="Select Channel"
                                                // value={setSelectedPreChannels}
                                                isDisabled={!preferredChecked}
                                                styles={customStyles}
                                            />
                                        </Form.Group>

                                        <Form.Group controlId="" className="pb-1">

                                            <div key={'Save Search'} className="mb-1">
                                                <Form.Check
                                                    type="checkbox"
                                                    id="saveSearchCheckbox"
                                                    label="Save Search"
                                                    style={{ textAlign: "left" }}
                                                    onChange={handleSaveChange}

                                                />
                                            </div>
                                        </Form.Group>
                                        <Form.Group controlId="savekey" className='d-flex align-items-center pb-2'>
                                            <Form.Control type="text" autoComplete="off" placeholder='Add Key Word' className="me-1" style={{ maxWidth: "100%" }}
                                                disabled={!saveChecked} onChange={handleSavekeyChange} />
                                        </Form.Group>
                                        <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                            <div className='d-flex gap-3 mt-2 align-items-center'>
                                                <Form.Control type="text" value={maxResult} onChange={(e) => { checkMaxResult(e.target.value) }} autoComplete="off" placeholder='Max Results' className="me-1" />
                                                <Col >
                                                    <Button variant="outline-dark" className="w-100" onClick={() => { handleReset() }} disabled={!courseId && !unitId && !query && !maxResult && !lessonContent && !lessonId}>
                                                        Clear
                                                    </Button>
                                                </Col>
                                                <Col >
                                                    <Button onClick={() => { handleClick() }} variant="primary" type="button" className="w-100">
                                                        Search
                                                    </Button>
                                                </Col>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                    {/* <Form className="mb-4 d-flex justify-content-end gap-2" style={{ maxWidth: "200px", width: "100%" }}>
                                        <Col className='d-flex flex-column'>
                                            <Form.Group controlId="maxResultToReturn" className="mb-2" >
                                                <Form.Control type="text" value={maxResult} onChange={(e) => { checkMaxResult(e.target.value) }} autoComplete="off" placeholder='Max Results' />
                                            </Form.Group>
                                            <div className='d-flex gap-3 mt-auto'>
                                                <Col >
                                                    <Button variant="outline-dark" className="w-100" onClick={() => { handleReset() }} disabled={!courseId && !unitId && !query && !maxResult && !lessonContent && !lessonId}>
                                                        Clear
                                                    </Button>
                                                </Col>
                                                <Col >
                                                    <Button onClick={() => { handleClick() }} variant="primary" type="button" className="w-100">
                                                        Search
                                                    </Button>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Form> */}
                                </div>
                                <Row>
                                    <Col xs='12'>
                                        <Button hidden={!showSpinner} variant="link" className="mt-2 mb-5 btn-spinner ms-0 text-decoration-none" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                                className='me-1'
                                            />
                                            Search in progress...
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                            {tableData && tableData.length !== 0 && <SearchListTable data={tableData} recentSearch={getSearchedList} status={setProcessStatus} />}
                        </div>
                    </Col>
                </Row>
            </Container>



        </section >




    );
};

export default VideoSeachComponents;
