import { Container, Row, Form, Table, Button, Col, Modal, Tabs, Tab } from 'react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import MenuDropdown from "../components/dropdownmenu";
import { toast } from 'react-toastify';

const SearchListTable = (props) => {
  const [handledList, setHandledList] = useState([]);
  const [searchList, setSearchList] = useState()
  const [isOpen, setIsOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState('');
  const [visibleCharacters, setVisibleCharacters] = useState(2000);
  const [textShow, setTextShow] = useState(false);
  const [parentCheck, setParentCheck] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteModel, setDeleteModel] = useState(false);
  const [deletedisable, setDeleteDisable] = useState(true);


  const navigate = useNavigate();
  const tableRef = useRef(null);
  const cellStyle = {
    maxWidth: '100px', /* Set your preferred max-width */
    whiteSpace: 'nowrap', /* Prevent text wrapping */
    overflow: 'hidden', /* Hide overflowing content */
    textOverflow: 'ellipsis', /* Show ellipsis for truncated content */
  };
  const handleParentCheck = (checked) => {
    if (checked) {
      setParentCheck(true)
    }
    else {
      setParentCheck(false)
      setSelectedRow([])
    }
  }
  const title = [
    {
      title: (
        <Form.Group controlId="subscriptionall">
          <Form.Check
            checked={parentCheck}
            type="checkbox"
            id='subscriptionall'
            onChange={(e) => { handleParentCheck(e.target.checked) }
            } />
        </Form.Group>
      ),
    },
    {
      title: 'Search name',
    },
    {
      title: 'Video Title',
    },
    {
      title: 'Course',
    },
    {
      title: 'Unit',
    },
    {
      title: 'Lesson',
    },
    {
      title: 'Video URL',
    },
    {
      title: 'Channel',
    },
    {
      title: 'Views',
    },
    {
      title: 'Likes',
    },
    {
      title: 'Duration',
    },
    {
      title: "Relevancy Score"
    },
    {
      title: 'Action',
    }
  ]
  const listngData = searchList ? handledList : props.data
  useEffect(() => {
    let formData_1 = localStorage.getItem('tokenData')
    axios.post('https://' + process.env.REACT_APP_HOST + '/webservice/rest/server.php?moodlewsrestformat=json&wsfunction=core_webservice_get_site_info', formData_1)
      .then(userdata => {
        if (!userdata.data.userid) {
          localStorage.setItem('login', false)
          navigate('/');
        }
      })
      .catch(error => {
        console.log(error)
      });
  }, [])
  const handleChange = (value, data) => {
    setSearchList(value);
    const handledData = data.filter((val) => {
      return (
        val.video_title.toLowerCase().indexOf(value.toLowerCase()) > -1 || val.video_title.indexOf(value) > -1
      );
    });
    setHandledList(handledData);
  };
  const modalClick = (id) => {
    setIsOpen(true);
    const url = `${process.env.REACT_APP_API_URL}/video-finder?id=${id}`;
    axios.put(url, {
      headers: {
        'Accept': 'application/json' // You can set the Accept header if needed
      }
    }).then(response => {
      setApiResponse(response.data.data);
    })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  const closePopup = () => {
    setIsOpen(false);
  };
  const handleShowMore = () => {
    setVisibleCharacters(prevVisibleCharacters => prevVisibleCharacters + 1000); // Increment by 200 characters
  };
  useEffect(() => {
    listngData && listngData.map((val) => { val.relevancy_score ? setTextShow(false) : setTextShow(true) })
  })
  const handleDeleteClick = () => {
    if (parentCheck) {
      for (var i = 0; i < props.data.length; i++) {
        var currentId = props.data[i].id;
        if (selectedRow.indexOf(currentId) === -1) {
          selectedRow.push(currentId);
        }
      }
    }
    setDeleteModel(true)
  }
  useEffect(() => {
    if (parentCheck || selectedRow.length > 0) {
      setDeleteDisable(false)
    } else {
      setDeleteDisable(true)
    }
  }, [parentCheck, selectedRow])
  const handleCheck = (checked, id) => {
    if (checked) {
      const temp = [...selectedRow, id];
      setSelectedRow(temp);
    } else {
      const temp = selectedRow.filter(val => val !== id);
      setSelectedRow(temp);
    }
  };
  const handleDelete = () => {
    const url = `${process.env.REACT_APP_API_URL}/video-finder?ids=${JSON.stringify(selectedRow)}`;
    axios.delete(url).then(response => {
      setSelectedRow([])
      setDeleteModel(false);
      props.recentSearch();
      toast.error('Deleted successfully')
    })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }
  return (
    <Container fluid className='mt-4'>
      <Form className="form-column">
        <Row>
          <Col>
            <div className='d-flex align-items-end p-0 gap-4'>
              <div>
                <Button variant="link" type="button" className="px-0 delete-link" onClick={() => { handleDeleteClick() }}
                  disabled={deletedisable}>
                  <div className="d-flex align-items center gap-1">
                    <img src={'/images/trash.svg'} alt='delete' />
                    Delete
                  </div>
                </Button>
              </div>
              <Form.Group controlId="search">
                <Form.Control type="text" autoComplete='off' value={searchList} onChange={(e) => handleChange(e.target.value, props.data)} placeholder='Search video title' />
              </Form.Group>
            </div>
          </Col>
          <Col className='d-flex align-items-end gap-2 justify-content-end'>
            <p hidden={!textShow} className='mb-0 blink-text'>Refresh the page after a few minutes</p>
            <DownloadTableExcel
              filename="searched videos"
              sheet={listngData[0]['search_name']}
              currentTableRef={tableRef.current}
            >
              <img src={'/images/download.png'} className='export-button' type="button" />
            </DownloadTableExcel>
          </Col>
          <div className='search-list-table'>
            <Table responsive className='mt-2' ref={tableRef}>
              <thead>
                <tr>
                  {title.map((val) => (
                    <th>{val.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {listngData && listngData.map((val) => (
                  <tr>
                    {val.process_status != "COMPLETED" ? props.status(true) :""}
                    <td>
                      <Form.Group controlId="subscription">
                        <Form.Check
                          type="checkbox"
                          id={val.id}
                          checked={
                            selectedRow.includes(val.id) || parentCheck ? true : false
                          }
                          onChange={(e) => { handleCheck(e.target.checked, val.id) }
                          }
                          disabled={parentCheck}
                        />
                      </Form.Group>
                    </td>
                    <td className="text-start" style={cellStyle}><a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={val.search_name}
                      data-tooltip-place="top"
                    >{val.search_name}</a></td>
                    <td style={cellStyle}><a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={val.video_title}
                      data-tooltip-place="top"
                    >{val.video_title}</a></td>
                    <td className="text-start" style={cellStyle}><a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={val.course_name}
                      data-tooltip-place="top"
                    >{val.course_name}</a></td>
                    <td style={cellStyle}><a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={val.unit_title}
                      data-tooltip-place="top"
                    >{val.unit_title}</a></td>
                    <td style={cellStyle}><a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={val.lesson_name}
                      data-tooltip-place="top"
                    >{val.lesson_name}</a></td>
                    <td style={cellStyle}><a href={val.video_url} target="_blank">{val.video_url}</a></td>
                    <td className="text-start" style={cellStyle}><a
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={val.channel_title}
                      data-tooltip-place="top"
                    >{val.channel_title}</a></td>
                    <td style={cellStyle}>{val.views}</td>
                    <td style={cellStyle}>{val.likes}</td>
                    <td style={cellStyle}>{val.duration}</td>
                    <td style={cellStyle}>{val.relevancy_score ? val.relevancy_score : "In progress.."}</td>
                    <td> <MenuDropdown clickfunction={modalClick} reloadfunction={props.recentSearch} id={val.id} /></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Row>
      </Form>
      {isOpen && (
        <div className="modal">
          <div className="modal-content" style={{ maxHeight: '650px', minHeight: '650px', overflowY: 'auto' }}>
            <span className="close" onClick={closePopup}>&times;</span>
            <center className="mb-4">
              <h2>{apiResponse.course_name}</h2>
            </center>
            <h4 className="mb-4"> Unit : {apiResponse.unit_title}</h4>
            <Modal.Body>
              <Tabs defaultActiveKey="questions" id="nav-tabs">
                <Tab eventKey="questions" title="Questions">
                  {apiResponse.questions ? (
                    <div className="content text-start mt-2">
                      {apiResponse.search_type == 1 ? <>
                        <h5 className="mb-3">Questions From Transcript</h5>
                        {apiResponse.questions.map((item, index) => (
                          <div key={index}>
                            <h6>{`${index + 1}) ${item.question_text}`}</h6>
                            <p className="mb-5"><b style={{ fontStyle: 'italic' }}>Answer : </b>{item.answer_text}</p>
                          </div>
                        ))}
                      </> : <>

                        <h5 className="mb-3">Questions Relavancy - Course Content</h5>
                        {apiResponse.questions.map((item, index) => (
                          <>
                          <div>
                              {item.quiz_details.map((element, indx) => (<>
                                <h6 key={indx}>{element.question_text}  {`( ${element.question_name})`} </h6>
                                <p className="mb-5"><b style={{ fontStyle: 'italic' }}>Covered : </b>{element.covered==true ? "Yes": "No"}</p>
                                </>
                               
                              ))}
                            </div>

                          </>
                        ))}

                      </>}

                    </div>
                  ) : (<p>In progress..</p>)}
                </Tab>
                <Tab eventKey="aiComment" title="AI Comment">
                  <div className="content text-start mt-2">
                    <label><b>Summary : </b></label><p>{apiResponse.summary ? apiResponse.summary : "In progress.."}</p>
                  </div>
                </Tab>
                <Tab eventKey="transcript" title="Transcript">
                  <div className="content text-start mt-2">
                    <p>
                      <label><b>Transcript : </b></label>
                      {apiResponse.transcript && (
                        <>
                          {visibleCharacters >= apiResponse.transcript.length
                            ? apiResponse.transcript
                            : `${apiResponse.transcript.slice(0, visibleCharacters)}...`}
                          {visibleCharacters < apiResponse.transcript.length && (
                            <span onClick={handleShowMore} style={{ color: 'blue', cursor: 'pointer' }}>
                              Show more
                            </span>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <div className="modal-footer">
              <button className="footer-close btn btn-primary" onClick={closePopup}>Close</button>
            </div>
          </div>
        </div>
      )}
      {deleteModel && <Modal show={deleteModel} onHide={() => setDeleteModel(false)} className='modal-block' centered size="lg">
        <Modal.Header>
          <h5>Confirmation</h5>
        </Modal.Header>
        <Modal.Body>
          Are you sure do you want to delete?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => { handleDelete() }}>
            Yes
          </Button>
          <Button variant="primary" onClick={() => setDeleteModel(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>}
    </Container>
  )
}
export default SearchListTable;