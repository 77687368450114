import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Badge, Container, Modal } from "react-bootstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";
import EditPromptFormModal from "./edit-prompt-form-modal";
import DeleteConfirmationModal from "./delete-confirmation-modal";

const EditPromptModal = ({ videoCode, onClose, enableChapterRegeneration }) => {
    const [promptType, setPromptType] = useState("VCHAPT");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const fetchData = useCallback(async () => {
        if (!videoCode || !promptType) {
            return
        }
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/video_prompt?video_url=https://www.youtube.com/embed/${videoCode}&prompt_type=${promptType}`)
            if (response.data?.data) {
                setData(response.data.data);
            } else {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/prompt?activity_id=1425&prompt_type=${promptType}`)
                const [d] = response.data.data.filter(d => !!d.active)
                setData([d]);
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }, [videoCode, promptType])

    const onEnable = useCallback((row) => {
        axios.post(`${process.env.REACT_APP_API_URL}/video_prompt`, {
            "prompts": [{
                "prompt_type": row.prompt_type,
                "prompt": row.prompt,
            }],
            "video_url": `https://www.youtube.com/embed/${videoCode}`
        })
            .then(() => {
                fetchData();
                enableChapterRegeneration();
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => { })
    }, [videoCode])

    const [deleteConfirmationId, setDeleteConfirmationId] = useState();
    const [deleting, setDeleting] = useState(false)
    const onDelete = useCallback((id) => {
        setDeleting(true)
        axios.delete(`${process.env.REACT_APP_API_URL}/video_prompt?prompt_id=${id}`)
            .then(() => {
                fetchData();
                setDeleteConfirmationId();
                enableChapterRegeneration();
            })
            .catch((err) => {
                console.log(err)
            }).finally(() => {
                setDeleting(false)
            })
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const [editPromptData, setEditPromptData] = useState()

    if (!!editPromptData) {
        return <EditPromptFormModal
            videoCode={videoCode}
            prompt={editPromptData.prompt}
            promptType={editPromptData.prompt_type}
            enableChapterRegeneration={enableChapterRegeneration}
            onClose={() => {
                setEditPromptData();
                fetchData();
            }}
        />
    }

    return (
        <>
            <Modal show onHide={onClose} centered style={{ zIndex: 10000 }} dialogClassName="modal-90w">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Prompt</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ overflowY: "auto", maxHeight: "500px" }}>
                    <section className="smart-editor-wrapper pt-4">
                        <Container fluid>
                            <div className="d-flex gap-3 mb-3">
                                <label htmlFor="promptTypeChapters">
                                    <input
                                        type="radio"
                                        name="promptType"
                                        id="promptTypeChapters"
                                        className="me-1"
                                        checked={promptType == "VCHAPT"}
                                        onChange={() => setPromptType("VCHAPT")}
                                    />
                                    Guide Chapters
                                </label>
                                <label htmlFor="promptTypeNotes">
                                    <input
                                        type="radio"
                                        name="promptType"
                                        id="promptTypeNotes"
                                        className="me-1"
                                        checked={promptType == "VNOTE"}
                                        onChange={() => setPromptType("VNOTE")}
                                    />
                                    Guide Notes
                                </label>
                                <label htmlFor="promptTypeChats">
                                    <input
                                        type="radio"
                                        name="promptType"
                                        id="promptTypeChats"
                                        className="me-1"
                                        checked={promptType == "VCHATS"}
                                        onChange={() => setPromptType("VCHATS")}
                                    />
                                    Guide Chats
                                </label>
                            </div>
                            {loading ? (
                                "Loading..."
                            ) : (
                                <table class="table table-bordered prompts-table">
                                    <thead>
                                        <tr>
                                            <th class="w-75">Prompt</th>
                                            <th>Created on</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.map((row) => (
                                            <tr>
                                                <td>
                                                    <ReactReadMoreReadLess
                                                        charLimit={200}
                                                        readMoreText={""}
                                                        readLessText={""}
                                                    >
                                                        {row.prompt}
                                                    </ReactReadMoreReadLess>
                                                </td>
                                                <td>
                                                    {row.created_on &&
                                                        new Date(row.created_on).toLocaleString()}
                                                </td>
                                                <td>
                                                    {row.active ?
                                                        <div style={{
                                                            padding: '4px 8px',
                                                            background: '#B2AC5F',
                                                            borderRadius: '4px',
                                                            color: 'white',
                                                            width: 'fit-content'
                                                        }}>Active</div> :
                                                        <div style={{
                                                            padding: '4px 8px',
                                                            borderRadius: '4px',
                                                            background: '#C4C4C4',
                                                            width: 'fit-content'
                                                        }}>Inactive</div>}
                                                </td>
                                                <td>
                                                    <div className="d-flex gap-4 align-item-center">
                                                        <div>
                                                            <img
                                                                style={{
                                                                    cursor: "pointer",
                                                                }}
                                                                src="/images/edit.svg"
                                                                alt="edit"
                                                                onClick={() => setEditPromptData(row)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <img
                                                                style={{
                                                                    cursor: "pointer",
                                                                    opacity: row.active ? 0.3 : 1,
                                                                    pointerEvents: row.active ? "none" : "inherit",
                                                                }}
                                                                src="/images/delete-grey.svg"
                                                                alt="delete"
                                                                onClick={() => setDeleteConfirmationId(row.id)}
                                                            />
                                                        </div>
                                                        <div>
                                                            <img
                                                                style={{
                                                                    cursor: "pointer",
                                                                    opacity: row.active ? 0.3 : 1,
                                                                    pointerEvents: row.active ? "none" : "inherit",
                                                                }}
                                                                src="/images/tick.svg"
                                                                alt="tick"
                                                                onClick={() => onEnable(row)}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </Container>
                    </section>
                </Modal.Body>
            </Modal >
            {deleteConfirmationId >= 0 &&
                <DeleteConfirmationModal
                    loading={deleting}
                    onClose={() => setDeleteConfirmationId()}
                    onConfirm={() => onDelete(deleteConfirmationId)}
                />
            }
        </>
    );
}

export default EditPromptModal;