import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import LoginPage from './components/login';
import PageLayout from './components/dashboard';
import { Routes, Route, useNavigate, Navigate} from "react-router-dom";
import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'


function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth <= 768 ? false : true);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768 ? true : false);
  const [loggedIn, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // useEffect(() => {
  //     const handleResize = () => {
  //         const isMobile = window.innerWidth <= 768;
  //         setIsMobileView(isMobile);
  //         setIsSidebarOpen(!isMobile);
  //     };
  //     handleResize();

  //     window.addEventListener('resize', handleResize);

  //     return () => {
  //         window.removeEventListener('resize', handleResize);
  //     };
  // }, []);
  useEffect(() => {
    let logInfo = localStorage.getItem('login')
    setLoggedIn(logInfo)
    if (logInfo == 'false') {
      navigate('/')
    }
  }, [localStorage.getItem('login')])

  const location = useLocation();
  return (
    <div className="App">
      <Container fluid className={`page-layout px-0 ${isMobileView ? 'mobile-view' : ''}`}>
        <div className='main-home'>
          <Routes>
            <Route
              exact path="/"
              element={
                loggedIn == 'true' ? (
                  <><Navigate to={"/home"} replace /></>

                ) : (
                  <LoginPage />
                )
              }
            />
            <Route
              path="/home*"
              element={loggedIn == 'false' ? (
                <Navigate to={"/"} replace />
              ) : <PageLayout location={location}/>
              }
            />
            <Route path='/home/:action' element={<PageLayout location={location}/>} />
          </Routes>
          <ToastContainer />
          <Tooltip id="my-tooltip" />
        </div>
      </Container>
    </div>
  );
}

export default App;
